import React from 'react'
import { FlashWrapper } from '../legacy/obj.flash-wrapper/flash-wrapper.component'
import { Footer } from '../org.footer/footer.component'
import { ClientHeader } from '../org.menu/client-header.component'
import { DoctorHeader } from '../org.menu/doctor-header.component'
import { isDoctorUrl } from 'utils/url'
import { NewClientHeader } from '../newHeader/client-header.component'
import { NewDoctorHeader } from '../newHeader/doctor-header.component'
import styled from 'styled-components'

const Spacer = styled.div`
  height: 115px;

  @media (min-width: 769px) {
    display: none;
  }
`
interface IndexLayoutProps {
  location: Location
  children: any
  showScheduleButton?: boolean
}
const IndexLayout: React.SFC<IndexLayoutProps> = ({ location, children, showScheduleButton }) => {

  const [hydrated, setHydrated] = React.useState(false);

  React.useEffect(() => {
    setHydrated(true);
  }, []);

  // tslint:disable:react-a11y-lang
  // why? https://tableless.com.br/declarando-idiomas-no-html/
  return (
    <>
      {process.env.GATSBY_MARCA === 'WEINMANN' ? (
        <>
          {isDoctorUrl(location.pathname) ? (
            <NewDoctorHeader showScheduleButton={showScheduleButton} />
          ) : (
            <NewClientHeader showScheduleButton={showScheduleButton} />
          )}
        </>
      ) : (
        <>
          {isDoctorUrl(location.pathname) ? (
            <DoctorHeader showScheduleButton={showScheduleButton} />
          ) : (
            <ClientHeader showScheduleButton={showScheduleButton} />
          )}
        </>
      )}

      <FlashWrapper />
      {/* <Spacer /> */}
      {hydrated ? children : <>{children}</>}
      <Footer isDoctor={isDoctorUrl(location.pathname)} />
    </>
  )
}

export default IndexLayout

IndexLayout.defaultProps = {
  showScheduleButton: true
}
