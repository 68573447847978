import { appPaths, externalPaths } from 'utils/path'
import { Color } from 'atomic'
import ColorFunc from 'color'
import { DefaultTheme } from 'styled-components'
import { ClientDoctorSelect } from 'utils/model/client-doctor-select'
import { Menu } from 'utils/model/menu'

export const getClientMenuItems = (theme: DefaultTheme, onContactClick: () => void) =>
  ({
    area: ClientDoctorSelect.client,
    items: [
      {
        title: externalPaths.fleuryExamResultsClient.name,
        to: externalPaths.fleuryExamResultsClient.path,
        external: true,
        target: '_blank'
      },
      { title: appPaths.exams.name, to: appPaths.exams.path.clientUrl },
      { title: appPaths.vaccines.name, to: appPaths.vaccines.path.clientUrl },
      { title: appPaths.convenio.name, to: appPaths.convenio.path.clientUrl },
      { title: appPaths.units.name, to: appPaths.units.path.clientUrl },
      { title: appPaths.contact.name, onClick: onContactClick },
      {
        title: 'Ouvidoria',
        to: 'https://maisservicos.fleury.com.br/ouvidoria',
        external: true
      }
    ],
    otherItems: {
      items: [
        {
          title: appPaths.otherServices.name,
          to: appPaths.otherServices.path.clientUrl,
          external: false
        },
        {
          title: appPaths.ourSpecialists.name,
          to: appPaths.ourSpecialists.path.clientUrl,
          external: false
        },
        {
          title: appPaths.news.name,
          to: appPaths.news.path.clientUrl,
          external: false
        },
        {
          title: appPaths.diseaseManual.name,
          to: appPaths.diseaseManual.path.clientUrl,
          external: false
        },
        {
          title: appPaths.fleuryMagazines.name,
          to: appPaths.fleuryMagazines.path.clientUrl,
          external: true
        },
        {
          title: externalPaths.referenceLaboratory.name,
          to: externalPaths.referenceLaboratory.path,
          external: true
        }
      ],
      actionColor: ColorFunc(theme.color.accessory)
        .darken(0.27)
        .hex()
    }
  } as Menu)

export const getDoctorMenuItems = (onContactClick: () => void) => {
  const result: Menu = {
    area: ClientDoctorSelect.doctor,
    items: [
      {
        title: externalPaths.fleuryExamResultsDoctor.name,
        to: externalPaths.fleuryExamResultsDoctor.path,
        external: true,
        target: '_blank'
      },
      { title: 'Manual de exames', to: appPaths.examManual.path.doctorUrl },
      { title: appPaths.medicalEducation.name, to: appPaths.medicalEducation.path.doctorUrl },
      { title: appPaths.ourSpecialists.name, to: appPaths.ourSpecialists.path.doctorUrl },
      { title: appPaths.contact.name, onClick: onContactClick },
      {
        title: 'Ouvidoria',
        to: 'https://maisservicos.fleury.com.br/ouvidoria',
        external: true
      }
    ],
    otherItems: {
      items: [
        {
          title: appPaths.otherServices.name,
          to: appPaths.otherServices.path.doctorUrl,
          external: false
        },
        {
          title: appPaths.units.name,
          to: appPaths.units.path.doctorUrl,
          external: false
        },
        {
          title: appPaths.convenio.name,
          to: appPaths.convenio.path.doctorUrl,
          external: false
        },
        {
          title: appPaths.diseaseManual.name,
          to: appPaths.diseaseManual.path.doctorUrl,
          external: false
        },
        {
          title: appPaths.news.name,
          to: appPaths.news.path.doctorUrl,
          external: false
        },
        {
          title: appPaths.medicalMagazines.name,
          to: appPaths.medicalMagazines.path.doctorUrl,
          external: true
        },
        {
          title: appPaths.doctorRegister.name,
          to: appPaths.doctorRegister.path.doctorUrl,
          external: false
        },
        {
          title: externalPaths.referenceLaboratory.name,
          to: externalPaths.referenceLaboratory.path,
          external: true
        },
        {
          title: externalPaths.calculator.name,
          to: externalPaths.calculator.path,
          external: true
        }
      ],
      actionColor: Color.GrayXDark
    }
  }
  return result
}
