import * as React from 'react'
import {
  CosmicjsInformacoesEstaticas,
  CosmicjsInstitucional,
} from 'site/src/data/graphql/graphql-types'

import { FaIcon } from 'atomic'
import SvgIcChat from 'atomic/atm.svg-icon/ic-chat'
import SvgIcTelefone from 'atomic/atm.svg-icon/ic-telefone'

import { getTelLink } from 'utils/number'
import { getDetailPageUrl } from 'utils/url'
import { appPaths, getPathUrl } from 'utils/path'
import { FooterContent } from 'utils/model/footer-content'

import { insertOtherLinksOnAbout } from '../footer.utils'

export const instagramUrl = 'https://www.instagram.com/fleury/'
export const facebookUrl = 'http://www.facebook.com/FleuryMedicinaeSaude'
export const messengerUrl = 'https://www.facebook.com/messages/t/FleuryMedicinaeSaude'
export const youtubeUrl = 'http://www.youtube.com/fleurymedicinaesaude'

export const mapToFooterContent = (data, isDoctor: boolean) => {
  const mapToLinkList = (doctor: boolean) => (item: CosmicjsInstitucional) => ({
    title: item.title,
    url: getDetailPageUrl(getPathUrl(appPaths.institutional.path, doctor), item.slug)
  })

  const firstColLinks = data.allCosmicjsInstitucional.nodes.slice(0, 4).map(mapToLinkList(isDoctor))
  const secondColLinks = data.allCosmicjsInstitucional.nodes.slice(4).map(mapToLinkList(isDoctor))
  insertOtherLinksOnAbout(secondColLinks, isDoctor)

  const appLinks = (data.appLinks as CosmicjsInformacoesEstaticas).metadata
  const androidUrl: string = appLinks.playStoreLink
  const iosUrl: string = appLinks.appStoreLink

  const contact = (data.contact as CosmicjsInformacoesEstaticas).metadata

  const phoneItem = contact.telefoneContatoMedico &&
    contact.telefoneContato && {
    //title: removeTelAreaCode(isDoctor ? contact.telefoneContatoMedico : contact.telefoneContato),
    title: isDoctor ? contact.telefoneContatoMedico : contact.telefoneContato,
    icon: <SvgIcTelefone width={20} />,
    url: getTelLink(isDoctor ? contact.telefoneContatoMedico : contact.telefoneContato)
  }

  const content: FooterContent = {
    section1: {
      title: 'O Fleury',
      links: firstColLinks
    },
    section2: {
      links: [{
        url: '/institucional/parceria',
        title: 'Parceria',
        target: '_blank'
      },
      {
        url: 'https://www.contatoseguro.com.br/grupofleury',
        title: 'Canal de Confiança',
        target: '_blank'
      },
      {
        url: '/institucional/direitos-dos-pacientes',
        title: 'Direitos e Deveres',
        target: '_blank'
      },
      {
        url: '/historia',
        title: 'Historia',
        target: '_blank'
      },
    ], 
    },
    section3: {
      links: [
        {
          url: 'http://www.grupofleury.com.br/SitePages/Home.aspx',
          title: 'CAC (Central de atendimento ao cliente)',
          target: '_blank'
        },
        {
          url: 'http://ri.fleury.com.br',
          title: 'Investidores',
          target: '_blank'
        },
        // {
        //   url: 'http://suprimentos.fleury.com.br/Paginas/default.aspx',
        //   title: 'Seja um Fornecedor',
        //   target: '_blank'
        // },
        {
          url: 'https://trabalheconosco.vagas.com.br/grupo-fleury',
          //url: 'https://career8.successfactors.com/career?company=FLEURY',
          title: 'Trabalhe Conosco',
          target: '_blank'
        },
        {
          url: getPathUrl(appPaths.faq.path, isDoctor),
          title: 'Perguntas Frequentes',
          target: '_self'
        },
        {
          url: 'https://maisservicos.fleury.com.br/ouvidoria',
          title: 'Ouvidoria',
          target: '_self'
        }
      ]
    },
    sectionContact: {
      links: [
        // {
        //   title: 'Whatsapp',
        //   icon: <SvgIcWhatsapp width={20} />,
        //   url: contact.agendamentoWhatsappLink,
        //   target: '_blank'
        // },
        phoneItem,
        {
          title: 'Chat-Online',
          icon: <SvgIcChat width={20} />,
          url: isDoctor ? contact.chatOnlineLinkMedico : contact.chatOnlineLink,
          target: '_blank'
        },
        {
          title: 'Messenger',
          icon: <FaIcon.Messenger size="lg" />,
          url: messengerUrl,
          target: '_blank'
        }
      ]
    },
    sectionMedia: {
      links: [
        {
          title: 'Instagram',
          icon: <FaIcon.Instagram size="lg" />,
          url: instagramUrl,
          target: '_blank'
        },
        {
          title: 'Facebook',
          icon: <FaIcon.Facebook size="lg" />,
          url: facebookUrl,
          target: '_blank'
        },
        {
          title: 'Youtube',
          icon: <FaIcon.Youtube size="lg" />,
          url: youtubeUrl,
          target: '_blank'
        }
      ]
    },
    sectionApps: {
      androidUrl,
      iosUrl,
      title: 'Aplicativos Fleury',
      target: '_blank'
    }
  }

  return content
}
